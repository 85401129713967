.navbar-container {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(216, 63%, 57%, 0.8);
  padding: 20px;
  font-family: "Aclonica";
  color: black;
}

.small-image {
  width: 240px;
  height: 240px;
  margin-left: 47px;
  margin-top: -50px;
}

.nav-items {
  margin-bottom: 20px;
}

.nav-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 0;
  cursor: pointer;
}

.nav-item:hover {
  color: inherit;
}

.nav-icon {
  margin-right: 20px;
}

.terms,
.policy {
  font-size: 12px;
  text-align: center;
  font-family: Aclonica;
  margin-top: auto;
}

.tutors-profile-container {
  margin-left: 300px;
  padding: 2% 4% 0 2%;
}