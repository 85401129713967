* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: sans-serif;
}

.illustration img {
  max-height: 500px;
  width: auto;
}

.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40%;
  margin-left: 32%;
}

.wrapper p {
  font-size: 0.85rem;
  margin-top: 1rem;
}

.form {
  padding: 1.5rem;
  -ms-flex-preferred-size: 100vw;
  flex-basis: 100vw;
}

.form .heading {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.or {
  margin: 1rem 0;
}

.form label {
  display: block;
  margin: 1.25rem 0 1rem 0;
}

.form input {
  height: 40px;
  width: 100%;
  padding: 15px;
  background-color: #f1f9ff;
  border: 2px solid #bce0fd;
  border-radius: 8px;
}

.form button {
  height: 40px;
  width: 100%;
  background-color: #258de6;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  display: block;
  margin: 0 auto;
  font-weight: bold;
  margin-top: 1.5rem;
  border-radius: 8px;
}

.signUp .illustration {
  order: 2;
  justify-self: flex-end;
  margin-left: 2rem;
}

button:hover {
  filter: brightness(95%);
}

button:active {
  transform: scale(0.98);
}